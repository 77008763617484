.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.userPoolSelection {
  display: flex;
}

.options > div {
  padding: 10px;
}

.getAuthContainer {
  display: flex;
  width: 90vw;
}

.getAuthContainer > div {
  width: 50%;
}
button {
  padding: 5px;
  margin: 10px;
}
